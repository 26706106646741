<template>
  <div class="background background-footer">
    <footer class="simple-footer-wrapper section" id="footercontent">
      <div class="simple-footer section-inner">
        <ul class="copyright-and-other-links size-2xs">
          <li class="copyright">
            {{ content.copyright }}
          </li>
          <template v-if="content.other_links?.length">
            <li v-for="link in content.other_links" :key="link.uid">
              <NuxtLink
                :to="strippedURL(link.link_url)"
                :target="link.link_url?.target || '_self'"
              >
                {{ link.link_label }}
              </NuxtLink>
            </li>
          </template>
        </ul>
        <ul v-if="content.social_networks" class="social-networks">
          <li
            v-for="socialNetwork in content.social_networks"
            :key="socialNetwork.uid"
          >
            <NuxtLink
              :to="strippedURL(socialNetwork.link)"
              :target="socialNetwork.link?.target || '_self'"
              :title="socialNetwork.title"
              class="size-2xs"
              @click="trackNetwork(socialNetwork.title)"
            >
              <SvgIcon :name="`func_${socialNetwork.logo}`" />
            </NuxtLink>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>

<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
});

const config = useRuntimeConfig();
const countryPrefix = config.public["storyblokCountryPrefix"];

// Tracking

const trackingStore = useTrackingStore();

const trackNetwork = (name) => {
  trackingStore.sendTrackingData({
    event: "click.navigation",
    click: useSnakeCaseFilter(useSnakeCaseFilter(name)),
    click_chapter1: "footer",
    click_chapter2: "link",
  });
};
</script>

<style lang="scss" scoped>
.simple-footer-wrapper {
  padding-top: 2.5rem;
  padding-bottom: 1.12rem;
  .simple-footer {
    background-color: var(--brand);
    border-radius: $radius-s;
    display: flex;
    flex-direction: column;
    padding: 2.5rem col-span();
    gap: 2rem;
    color: var(--solid-07);
  }
  a {
    color: var(--solid-07);
    text-decoration: none;
  }
  .copyright-and-other-links {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  .social-networks {
    display: flex;
    gap: 1.5rem;
  }
  .social-networks > * {
    width: 1.25rem;
    height: 1.25rem;
  }

  @include for-tablet-landscape-up {
    padding-top: 4rem;
    padding-bottom: 3.5rem;
    .simple-footer {
      border-radius: $radius-l;
      flex-direction: row;
      justify-content: space-between;
      padding-right: 2.5rem;
    }
    .copyright-and-other-links,
    .social-networks {
      align-items: center;
    }
    .copyright-and-other-links {
      flex-direction: row;
      gap: 1.5rem;
    }
    .social-networks > * {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
</style>
